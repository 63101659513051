export const useGoogleLogin = () => {
  const config = useRuntimeConfig()

  const login = () => {
    navigateTo(`${config.public.API_URL}oauth/login`, {
      external: true,
    })
  }

  return {
    login,
  }
}
